<template>
  <v-row>
    <!-- Advanced usage  -->
    <v-col md="12">
      <base-card class="h-full">
        <v-card-title>
          {{$t('general.list_of_children')}}
        </v-card-title>
        <v-card-text>
          <v-row v-if="Lb_IsLoaded">
            <v-list min-width="400" >
              <v-list-item
                v-for="(child, i) in children"
                :key="i"
                @click="gotToStudentDashboard(child.AdressRolle, child.AddressID)"
              >
                <v-list-item-content>
                  <v-list-item-title v-text="child.name" />
                </v-list-item-content>
                <v-list-item-content>
                  <v-list-item-subtitle v-text="child.education" />
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-row>
        </v-card-text>
      </base-card>
    </v-col>
  </v-row>
</template>

<script>
import store from '@/store'
import { get } from '../../../worker/worker-api';
  export default {
    name: 'Children',
    metaInfo() {
      return{
        // title will be injected into parent titleTemplate
        title: this.$t('general.children'),
      }
    },
    data () {
      return {
        children: [],
        Lb_IsLoaded: false
      }
    },
    methods: {
      getChildrenOfParents(Ai_SSP_AddressRoleID,Ai_PlanningPeriodID) {
          store.dispatch('changeThemeLoadingState', true);
          this.Lb_IsLoaded =false;
          return get(`${process.env.VUE_APP_SMT_API_URL}/parents/${Ai_SSP_AddressRoleID}/children/planning-period/${Ai_PlanningPeriodID}`, {})
          .then(response => {
            this.children = response.children;
            setTimeout(() => store.dispatch('changeThemeLoadingState', false), 700)
            this.Lb_IsLoaded = true;
        })
        .catch(error => {
          this.Lb_IsLoaded = true;
          store.dispatch('changeThemeLoadingState', false);
        });
      },
      gotToStudentDashboard(Ai_ChildAdressRolle, Ai_ChildAddressID) {
        this.$router.push({ name: 'student', params: { ChildAdressRolle: Ai_ChildAdressRolle,ChildAddressID: Ai_ChildAddressID } })
      }
    },
    created() {
      this.getChildrenOfParents(this.$smt.getAddressRoleID(this.$route.meta.role), store.getters.getPlanningPeriodID);
    }
  }
</script>
